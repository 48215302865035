.card-catalog {
	.card-list {
		display: grid;
		grid-auto-rows: 1fr;
		grid-auto-columns: 1fr;
		column-gap: 22px;
		row-gap: 22px;

		grid-template-columns: repeat(1, minmax(0, 1fr));

		@media screen and (min-width: 1200px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@media screen and (min-width: 1600px) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		@media screen and (min-width: 2000px) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}

		@media screen and (min-width: 2400px) {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}

		.card-container {
		}
	}

	.paginator {
		margin-top: 40px;
	}
}
