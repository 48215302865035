.feedback-widget {
	margin: 90px auto 0 auto;
	border: 1px solid var(--theme-feedback-border-color);
	border-radius: 4px;
	max-width: 700px;
	background-color: var(--theme-feedback-background-color);
	padding: 20px;

	& > h2.feedback-heading {
		margin-top: 0;
	}

	.sentiment-container {
		display: flex;
		flex-flow: row nowrap;
		gap: 12px;
		align-items: center;

		.sentiment-button {
			font-size: 24px;
			line-height: 0;
			padding: 6px;
			margin: 0;
			border: 1px solid transparent;
		}

		&.collapsed {
			justify-content: center;

			.sentiment-button {
				padding: 0;
			}
		}
	}

	.input-row {
		display: flex;
		flex-direction: row;
		gap: 50px;
		.dx-label {
			flex-grow: 1;

			&.invalid {
				.dx-textbox,
				.dx-textarea {
					border-color: red;
				}
			}
		}
	}

	.message-text {
		textarea {
			min-width: 100%;
			min-height: 200px;
		}
	}

	.submit-buttons {
		display: flex;
		flex-flow: row nowrap;
		flex-direction: row-reverse;
		gap: 20px;

		& > * {
			margin: 0;
		}
	}
}
