.toggle-group {
	margin: 20px 0;

	.toggle-label {
		font-size: 12px;
		color: var(--theme-core-control-label-color);
	}

	.toggle-group-buttons {
		display: flex;
		flex-flow: row wrap;

		& > .toggle-button {
			margin: 0;
			border-radius: 0;
			padding: 3px 15px;

			&:first-child {
				border-radius: 4px 0 0 4px;
			}

			&:last-child {
				border-radius: 0 4px 4px 0;
			}
		}
	}
}
