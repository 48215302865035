@import '../../../../../theme/variables.scss';
.dev-app-settings {
	h2:first-child {
		margin-top: 0;
	}
    .dev-app-header {
        margin-top: 30px;
    }
    .syntax-highlighting-header {
        margin-top: 30px;
    }
	.dx-checkbox:first-of-type {
		margin-top: 15px;
	}
    .label-text {
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;
		color: var($--theme-dxcheckbox-text-color);
	}
    .units {
        color: var($--theme-core-text-color);
    }
    .description {
        margin: 0 0 0 8px;
    }
    .disabled {
        color: var($--theme-core-control-disabled-text-color)
    }
    .number-label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 15px 0;
    }
    input[type='number'] {
		// Hide default appearance
		-webkit-appearance: none;
		appearance: none;
		// Custom appearance
		margin: 0 4px 0 0;
		width: 50px;
		height: 16px;
		border: 1px solid var($--theme-core-control-border-color);
		border-radius: 2px;
		background-color: var($--theme-core-control-background-color);
		flex-shrink: 0;
	}
    input[type='number']:disabled {
        border-color: var($--theme-dxcheckbox-disabled-border-color);
        color: var($--theme-core-control-disabled-text-color);
    }
	// Hover - number
	input[type='number']:not(:disabled):hover {
		border-color: var($--theme-dxcheckbox-hover-color);
	}
    // The following styles use class selectors rather than element/query selectors to avoid !important tags
	// Focus - number
	.payload-limit-input:focus {
		outline: var($--theme-dxcheckbox-outline-color) solid 2px !important;
	}
	// Disable default focus outline
	.payload-limit-input:focus-visible {
		outline: 0;
	}
}