@import '../../../theme/variables.scss';
.announcement-view {
	.announcement-tags {
		margin: 0 0 30px 0;
	}
	.announcement {
		display: flex;
		flex-flow: row nowrap;
		overflow: hidden;
		position: relative;
		margin-top: 0;

		.announcement-content {
			flex-grow: 1;
			padding: 5px;
			overflow: hidden;
			position: relative;
			margin-top: 0;

			h3:first-of-type {
				margin-top: 12px;
			}
		}

		.announcement-detail {
			position: relative;
			flex-grow: 0;
			flex-shrink: 0;
			width: 340px;
			display: flex;
			flex-flow: column nowrap;
			padding: 12px;
			overflow-y: hidden;
			margin-top: 0;

			ul {
				list-style: none;
				margin-left: 0px;
				padding-left: 0px;
			}
			p {
				margin-bottom: 0px;
			}

			.first-item {
				margin-top: 0;
			}

			h3 {
				display: inline-block;
				margin-bottom: 10px;
				margin-top: 30px;
			}
		}
	}
}
