@import '../../../theme/variables.scss';

.openapi-explorer-container {
	.openapi-explorer {
		.api-resource-accordion {
			margin-top: 0;
			position: relative;

			.accordion-header-button {
				position: absolute;
				left: -28px;
				font-size: 35px;
				display: none;
			}

			.accordion-heading:hover {
				.accordion-header-button {
					display: inline-block;
				}
			}
		}

		// This is used multiple places in sub-components
		.required-text {
			color: var(--theme-alertblock-info-border-color);
			font-weight: bold;
		}
	}
}
