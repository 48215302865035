@import '../../../theme/variables.scss';

// Lifted from OperationTile.scss
$get: #52cef8;
$post: #7d2;
$patch: #ff1dce;
$put: #ffda17;
$delete: red;
$head: #ff8f14;

@mixin operationColorRule($verb, $color) {
	&.operation-#{$verb} {
		.verb {
			color: darken($color: $color, $amount: 20);
			text-align: left;
		}
	}
}

.api-explorer-layout {
	.layout-header {
		.api-explorer-logo {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			font-weight: normal;
			font-size: 16px;
			line-height: 16px;
			cursor: pointer;

			.header-image {
				height: 32px;
				margin: 0 10px 0 20px;
			}

			.header-image-text {
				color: var($--theme-core-control-label-color);
			}
		}
	}

	.layout-body {
		padding-bottom: 80px;

		&.nav-collapsed {
			.layout-navigation:hover {
				width: 40vw;
			}
		}

		.layout-navigation {
			border: 1px solid var($--theme-core-layout-border-color);
			padding: 0;
			width: 40vw;
			z-index: 100;

			.operation-list {
				width: auto !important;
				padding: 20px 0 20px 20px;

				.operation-filter-widget {
					.dx-textbox {
						margin-right: 16px;
					}

					.http-verb-filter {
						.verb-filter-checkbox {
							margin-right: 36px;
						}
					}
				}

				.dx-accordion-group {
					margin-left: -20px;

					.accordion-heading {
						padding-left: 12px;
					}

					.accordion-content {
						padding-left: 12px;
						padding-right: 0;
						display: flex;
						flex-flow: column nowrap;
					}
				}

				& > .h7:first-of-type {
					margin-top: 0;
				}

				.category-header {
					display: flex;
					flex-flow: column nowrap;
					gap: 6px;

					.category-name {
						font-size: 18px;
						font-weight: bold;
					}

					.cateogry-description {
						font-size: 12px;
						font-weight: normal;
					}
				}

				.operation-info {
					margin-left: -12px;
					padding: 7px 0 7px 12px;
					font-weight: bold;

					&:hover {
						background-color: #00000033;
					}
				}
			}
		}

		.operation-info {
			display: flex;
			flex-direction: column;
			cursor: pointer;
			font-size: 16px;
			color: inherit;

			@include operationColorRule('get', $get);
			@include operationColorRule('post', $post);
			@include operationColorRule('patch', $patch);
			@include operationColorRule('put', $put);
			@include operationColorRule('delete', $delete);
			@include operationColorRule('head', $head);

			.operation-key {
				display: flex;
				flex-flow: row nowrap;
				overflow: hidden;
				gap: 6px;

				& > span {
					flex-shrink: 0;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				& > .tag {
					font-size: 13px;
					line-height: 13px;
					font-weight: normal;
					align-self: baseline;

					&.tag-preview {
						color: var($--theme-alertblock-info-text-color);
						background-color: var($--theme-alertblock-info-background-color);
						border-color: var($--theme-alertblock-info-border-color);
					}

					&.tag-unstable {
						color: var($--theme-alertblock-warning-text-color);
						background-color: var($--theme-alertblock-warning-background-color);
						border-color: var($--theme-alertblock-warning-border-color);
					}

					&.tag-internal {
						color: var($--theme-alertblock-critical-text-color);
						background-color: var($--theme-alertblock-critical-background-color);
						border-color: var($--theme-alertblock-critical-border-color);
					}
				}
			}

			.operation-description {
				font-size: 14px;
				font-weight: normal;
				text-align: left;
			}
		}

		.layout-content {
			margin-left: 40vw;

			.banner-notice {
				position: relative;
				z-index: 9;
				margin: -20px -30px 12px -28px;
				padding: 20px 30px;
				color: var($--theme-alertblock-info-text-color);
				background-color: var($--theme-alertblock-info-background-color);
				border: 1px solid var($--theme-alertblock-info-border-color);

				strong {
					font-size: 16px;
				}
			}

			.no-operations {
				text-align: center;
				font-size: 24px;
				font-style: italic;
				font-weight: lighter;
				margin-top: 60px;
			}

			.operation-tab-container {
				display: flex;
				flex-flow: row wrap;
				border-bottom: 1px solid var($--theme-core-layout-border-color);

				.operation-info {
					flex-flow: row nowrap;
					padding: 6px 12px;
					border-bottom: 1px solid transparent;
					border-radius: 0;

					&.selected {
						border-color: var($--theme-core-control-punch-color);
						font-weight: bold;

						.remove-operation-button {
							visibility: visible;
						}
					}

					&:hover {
						border-color: var($--theme-core-control-punch-color);

						.remove-operation-button {
							visibility: visible;
						}
					}

					.remove-operation-button {
						line-height: 0;
						font-size: 12px;
						margin-left: 12px;
						visibility: hidden;
					}
				}

				.close-all-button {
					margin: 4px 0 4px auto;
					padding: 0px 15px;
				}
			}
		}
	}
}
