@import '../../../theme/variables.scss';

.dx-card-plain {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: space-between;
	gap: 8px;
	height: 100%;

	.title {
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
	}

	.subtitle {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		color: var($--theme-core-tag-background-color);
	}

	.publish-date {
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
	}

	.text {
		flex-grow: 1;
		flex-shrink: 1;

		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		overflow: hidden;
	}

	.card-authors {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		gap: 0px;
		background-color: #00000033;
		border-radius: 0 0 4px 4px;
		margin: 0 -20px -20px -20px;
		padding: 8px 20px 8px 20px;

		.author-info {
			margin: 3px 24px;
		}
	}

	.tags {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		gap: 0px;
		align-items: center;
		justify-content: center;

		.tag {
			margin: 3px 8px;
		}
	}
}
