.tag {
	font-size: 12px;
	line-height: 14px;
	color: var(--theme-core-tag-background-color);
	border: 1px solid var(--theme-core-tag-background-color);
	border-radius: 8px;
	padding: 3px 10px;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}
