.operation-filter-widget {
	.filter-group {
		.dx-label {
			margin-bottom: 10px;
		}
		.http-verb-filter,
		.visibility-filter {
			margin: 7px 0px 20px 0px;
			display: flex;
			flex-wrap: wrap;
		}
		.filter-checkbox {
			margin: 0 55px 0 0;
		}
	}
}
