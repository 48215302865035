@property --opacity {
	syntax: '<percentage>';
	inherits: false;
	initial-value: 10%;
}

.inline-placeholder-container {
	position: relative;
	display: inline-block;

	.inline-placeholder {
		--opacity: 90%;
		--c: #5edbf733;
		--c2: #5edbf710;
		position: absolute;
		top: 0;
		left: 0px;
		right: 0px;
		bottom: 0;

		background: linear-gradient(20deg, var(--c) 0%, var(--c2) 100%);
		opacity: var(--opacity);
		animation: a 1s ease-in-out infinite alternate;
	}

	.inline-placeholder-text {
		display: inline-block;
		color: var(--theme-core-control-disabled-text-color);
		padding: 1px 6px;
		margin: -1px 0px;
	}
}

@keyframes a {
	0% {
		--opacity: 90%;
	}
	20% {
		--opacity: 60%;
	}
	100% {
		--opacity: 20%;
	}
}

.master {
	color: black;
	float: right;
}

@media (max-width: 900px) {
	.master {
		display: none;
	}
}
//
