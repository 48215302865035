@import '../../theme/variables.scss';

.page-content {
	.title-box {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 4px;
		margin: 8px 0 20px 0;

		.title-buttons {
			display: flex;
			flex-flow: column nowrap;
			gap: 6px;
		}

		.page-title {
			margin: 0;
		}
	}

	&.bookmarkable {
		.title-box {
			margin-left: -22px;
		}
	}

	.author-listing {
		display: flex;
		flex-flow: row wrap;
		gap: 12px;

		& > * {
			flex-basis: 45%;
			margin: 0;
		}

		@media screen and (max-width: 1300px) {
			& > * {
				flex-basis: 100%;
			}
		}
	}
}
