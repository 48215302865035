@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/sass/event';
@import 'react-big-calendar/lib/sass/variables';
@import 'react-big-calendar/lib/sass/toolbar';
@import '../../../theme/variables.scss';

$newfeatureColor: #00ae0e;
$infoColor: #009fc7;
$changeColor: #cc3336;
$releasenoteColor: #c78800;

.announcement-calendar-view {
	.newfeature-border {
		border-color: $newfeatureColor;
	}

	.newfeature-bg {
		background-color: $newfeatureColor;
	}

	.info-border {
		border-color: $infoColor;
	}

	.info-bg {
		background-color: $infoColor;
	}

	.change-border {
		border-color: $changeColor;
	}

	.change-bg {
		background-color: $changeColor;
	}

	.releasenote-border {
		border-color: $releasenoteColor;
	}

	.releasenote-bg {
		background-color: $releasenoteColor;
	}

	.calendar-description {
		display: inline-flex;
		gap: 2px;

		.color-code-span {
			width: 15px;
			margin: 3px 3px 3px 0;
		}
	}

	.rbc-event-content {
		cursor: auto;
	}

	.rbc-event-allday {
		width: 96% !important;
		margin: 0 auto !important;
	}

	.announcement-card {
		display: inline-flex;
		flex-direction: row;
		gap: 0;
		border-radius: 5px;
		text-align: left;
		width: 100%;
		border-width: 1px;
		border-style: solid;

		.title {
			flex: 1;
			padding: 0 5px;
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: var(--theme-core-control-text-color);
			background-color: var(--theme-core-control-alt-background-color);
			border-radius: 0 5px 5px 0;
		}

		.color-code {
			width: 15px;
			padding: 0;
			margin: 0;
			border-radius: 3px 0 0 3px;
		}
	}

	.announcement-type-filter-group {
		.dx-label {
			margin-bottom: 10px;
		}

		.announcement-type-filter {
			margin: 7px 0px 20px 0px;
			display: flex;
			flex-wrap: wrap;

			.filter-checkbox {
				margin: 0 55px 0 0;

				.label-text {
					display: inline-block;
					border-bottom-width: 2px;
					border-bottom-style: solid;
					border-bottom-color: inherit;
					color: var(--theme-core-text-color);
				}
			}
		}
	}

	.rbc-event {
		background-color: var($--theme-dxbutton-secondary-background-color);
		padding: 0;
		margin: 0;
		width: 97%;
		height: 80%;

		&.rbc-selected {
			background-color: white;
		}
	}

	.rbc-off-range-bg {
		background: var($--theme-announcement-outofrange-background-color);
	}

	.rbc-off-range {
		color: var($--theme-core-control-textbox-text-color);
	}

	.rbc-toolbar {
		& button {
			color: var($--theme-dxbutton-secondary-border-color);

			&:active,
			&.rbc-active {
				color: var($--theme-dxbutton-primary-text-color);
				border-color: var($--theme-dxbutton-primary-background-color);
				background-color: var($--theme-dxbutton-primary-background-color);

				&:hover,
				&:focus {
					color: var($--theme-dxbutton-primary-text-color);
					border-color: var($--theme-dxbutton-primary-background-color);
					background-color: var($--theme-dxbutton-primary-background-color);
				}
			}

			&:focus {
				color: var($--theme-dxbutton-secondary-border-color);
				border-color: var($--theme-dxbutton-secondary-border-color);
				background-color: var($--theme-dxbutton-secondary-background-color);
				box-shadow: 0 0 0 2px var($--theme-dxbutton-secondary-shadow-color);
			}

			&:hover {
				color: var($--theme-dxbutton-secondary-hover-border-color);
				background-color: var($--theme-dxbutton-secondary-background-color);
				border-color: var($--theme-dxbutton-secondary-border-color);
			}
		}
	}

	.rbc-today {
		background-color: var($--theme-announcement-today-background-color);
	}

	.rbc-btn-group {
		> button:nth-child(4) {
			display: none;
		}
	}
}
