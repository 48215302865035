@import '../../theme/variables.scss';

.public-logout {

    &-container {
        color: var($--theme-core-control-text-color);
        flex-grow: 0;
	    flex-shrink: 0;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        min-width: 90px;
        height: 20px;
        line-height: 0;
        text-decoration: underline var($--theme-core-link-color);

        a {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-decoration: underline;
        }
    }
}