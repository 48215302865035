.notifications-widget {
	position: relative;

	.dx-button.notifications-bell {
		position: relative;
		z-index: 1001;
		border: 1px solid transparent;
		border-bottom-width: 0;
		border-radius: 4px 4px 0 0;
		padding: 12px;
		font-size: 16px;
		line-height: 0;

		&:hover {
			border-color: transparent;
		}

		&.open {
			background-color: var(--theme-core-control-alt-background-color);
			border-color: var(--theme-core-control-border-color);
		}

		.icon.active-alerts {
			color: var(--theme-core-punch-color);
		}
	}

	.notifications-container {
		display: none;
		position: absolute;
		z-index: 1000;
		right: 0;
		top: 40px;
		width: 400px;
		max-height: 60vh;
		color: var(--theme-core-control-text-color);
		border: 1px solid var(--theme-core-control-border-color);
		background-color: var(--theme-core-control-alt-background-color);
		overflow-y: hidden;
		flex-flow: column nowrap;
		justify-content: stretch;
		align-items: stretch;

		&.open {
			display: flex;
		}

		.notifications-header {
			margin: 0;
			padding: 6px 12px;
			background-color: #00000033;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			gap: 6px;

			.h7 {
				margin: 0;
			}

			.dismiss-link {
				margin-left: auto;
				font-size: 12px;
				line-height: 12px;
			}
		}

		.no-notifications {
			margin: 24px;
			text-align: center;
			font-style: italic;
		}

		.notifications-list {
			overflow: auto;
			display: flex;
			flex-flow: column nowrap;
			justify-content: stretch;

			.notification {
				padding: 12px 18px 12px 12px;

				&:hover {
					background-color: #00000033;
				}

				&:first-child {
					padding-top: 6px;
				}

				.subject {
					margin: 0 0 3px 0;
					font-weight: bold;

					.important-pin {
						color: var(--theme-core-heading-seven-color);
						position: relative;
						bottom: -2px;
						left: -3px;
						font-size: 14px;
						line-height: 0;
						margin: 0 0;
						padding: 0;
					}

					.newtag {
						display: inline-block;
						margin-left: 6px;
						color: var(--theme-core-punch-color);
						font-size: 12px;
						font-weight: lighter;
					}
				}

				.content {
					& > *:last-child {
						margin-bottom: 0;
					}
				}

				.footer {
					text-align: right;
					margin: 0;
					font-weight: lighter;
					font-size: 12px;
					font-style: italic;

					&.new {
						color: var(--theme-core-punch-color);
					}
				}
			}

			.load-more-footer {
				margin: 0;
				border-radius: 0;
				padding: 0;
			}
		}
	}
}
