.dx-card {
	position: relative;
	height: 100%;
	width: 100%;
	border: 1px solid var(--theme-card-border-color);
	border-radius: 4px;
	padding: 20px;
	box-sizing: border-box;
	background-color: var(--theme-card-background-color);
	color: var(--theme-card-text-color);
}
