.path-segment {
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	margin-left: 10px;
	flex-grow: 1;

	.segment-content {
		display: flex;
		flex-flow: column nowrap;
	}

	.operations-container {
		display: flex;
		flex-flow: column nowrap;
		padding-left: 12px;
		margin-left: 12px;
		border-left: 1px solid grey;
	}

	.path-toggle {
		margin: 0;

		&:hover {
			background-color: #0000001f;
		}

		.path-parts {
			display: flex;
			flex-flow: row nowrap;
			align-items: baseline;
			gap: 2px;

			&:hover > .expander-button {
				display: inline-block;
			}

			.expander-button {
				display: none;
				margin-left: 3px;
				font-size: 14px;
				line-height: 0px;

				&:hover {
					color: var(--theme-core-punch-color);
				}
			}
		}
	}

	.last-part {
		margin-left: 22px;
	}
}
