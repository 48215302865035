.application-inspector {
	.warning-container {
		display: flex;
		flex-flow: column;
		width: 100%;
		justify-content: center;
		text-align: center;
		height: 200px;

		.icon {
			font-size: 48px;
			margin-top: 20px;
			color: #aeaeae;
		}

		span {
			color: #8b8b8b;
			font-size: 30px;
			font-weight: 300;
			margin-top: 20px;
			text-align: center;
		}
	}

	.usage-container {
		display: flex;
		justify-content: space-between;

		span {
			width: 50%;
		}
	}

	.scope-container {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;

		& > div {
			display: flex;
			flex-direction: column;
		}

		h3 {
			margin: 10px 0px 5px 0px !important;
		}
	}

	.application-inspector-accordion {
		margin: 55px 0px;
	}

	.error-warning {
		span {
			color: #cc3336;
		}
		.icon {
			color: #cc3336;
		}
	}

	.description {
		color: #8b8b8b;
	}
}
