@import '../../../theme/variables.scss';

$tallImageHeight: 156px;
$wideImageWidth: 250px;

.dx-card-image {
	height: 100%;

	&.tall {
		display: flex;
		flex-flow: column nowrap;

		.card-image {
			margin: -20px -20px 20px -20px;
			border-bottom: 1px solid var($--theme-card-border-color);
			border-radius: 4px 4px 0 0;
			height: $tallImageHeight;

			a > em {
				height: $tallImageHeight;
				display: inline-block;
			}

			img,
			.inline-placeholder-container {
				border-radius: 4px 4px 0 0;
				object-fit: cover;
				object-position: center center;
				width: 100%;
				height: $tallImageHeight;
			}

			.placeholder-image {
				object-fit: none;
				background-color: var($--theme-card-image-background-color);
			}

			&.mountains {
				.placeholder-image {
					object-position: center center;
				}
			}

			&.yeti {
				.placeholder-image {
					object-position: left center;
				}
			}
		}
	}

	&.wide {
		display: flex;
		flex-flow: row-reverse nowrap;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		.card-image {
			flex-shrink: 0;
			margin: auto 0;
			border: 1px solid var($--theme-card-border-color);
			border-radius: 4px;
			width: $wideImageWidth;
			height: 100%;
			max-height: 143px;
			background-color: var($--theme-card-image-background-color);
			overflow: hidden;

			img {
				border-radius: 4px;
				object-fit: none;
				object-fit: cover;
				object-position: center center;
				width: 100%;
				height: 100%;
				max-width: unset !important;
			}

			.placeholder-image {
				object-fit: none;
				object-position: center center;
			}
		}
	}
}
