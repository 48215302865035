.markdown-editor {
	display: flex;
	flex-flow: row nowrap;
	gap: 50px;

	& > div {
		flex-basis: 50%;

		textarea {
			width: 100%;
			height: 100%;
		}
	}
}
