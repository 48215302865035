.tag-cloud {
	display: flex;
	flex-flow: row wrap;
	gap: 6px;
	position: relative;

	&.align-left {
		justify-content: flex-start;
	}

	&.align-center {
		justify-content: space-evenly;
	}

	&.align-right {
		justify-content: flex-end;
	}

	.tag-container {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		&.allow-edit {
			.tag {
				// Extra space to accomodate the remove button
				padding-left: 17px;
				padding-right: 17px;
			}

			&:hover {
				.tag {
					// Remove extra space when showing remove button
					padding-left: 10px;
					padding-right: 3px;

					.remove-button {
						display: inline-block;
					}
				}
			}
		}
	}

	.remove-button {
		color: red;
		line-height: 0;
		display: none;
		margin: 0 -0 0 3px;
	}
}
