@import '../../../../../theme/variables.scss';

@keyframes spinner {
	0% {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}
	100% {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}

body {
	.rs-picker-popup,
	.rs-calendar-month-dropdown,
	.rs-calendar-btn-close,
	.rs-calendar-time-dropdown,
	.rs-calendar-time-dropdown-column-title {
		background-color: var($--theme-core-background-color);
		border: 1px solid var($--theme-core-layout-border-color);
		color: var($--theme-core-text-color);

		.rs-calendar-table-cell-is-today > .rs-calendar-table-cell-content {
			box-shadow: inset 0 0 0 1px var($--theme-dxbutton-secondary-border-color);
		}

		.rs-calendar-table-cell-selected,
		.rs-calendar-table-cell-selected:hover,
		.rs-calendar-month-dropdown-cell-active {
			.rs-calendar-table-cell-content,
			.rs-calendar-month-dropdown-cell-content {
				background-color: var($--theme-dxbutton-primary-background-color) !important;
				color: var($--theme-dxbutton-primary-text-color);
				span {
					color: var($--theme-dxbutton-primary-text-color) !important;
				}
			}
		}

		.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
			background-color: var($--theme-dxbutton-primary-background-color) !important;
			color: var($--theme-dxbutton-primary-text-color);
		}

		.rs-calendar-header,
		.rs-calendar-header-title-date,
		.rs-calendar-month-dropdown-year-active,
		.rs-calendar-header-title-time,
		.rs-calendar-header-meridian,
		.rs-calendar-header-backward,
		.rs-calendar-header-forward {
			:focus,
			:hover {
				color: var($--theme-dxbutton-primary-text-color);
			}
			color: var($--theme-core-text-color);
		}

		//OK button in popup
		.rs-picker-toolbar-right,
		.rs-btn-link {
			color: var($--theme-core-text-color);
			button {
				background-color: var($--theme-dxbutton-primary-background-color);
				color: var($--theme-dxbutton-primary-text-color);
				&:hover {
					background-color: var($--theme-dxbutton-primary-hover-background-color);
				}
			}
		}
	}
}

.model-property-container {
	display: flex;
	flex-direction: column;
	margin: 30px 0;

	.rs-picker {
		.rs-input-group {
			.rs-input-group-addon,
			.rs-btn-close {
				color: var(--theme-dxbutton-primary-hover-background-color);
			}
			background-color: var($--theme-core-background-color);
			input {
				background-color: var($--theme-core-background-color);
				color: var($--theme-core-text-color);
			}
		}
	}

	.label {
		margin: 0;
		font-size: 14px;
		line-height: 16px;
		font-weight: bold;
		color: var($--theme-core-text-color);

		&.focused {
			color: var($--theme-core-punch-color);
		}

		.property-name {
			display: inline-block;
			margin-right: 6px;
		}

		.property-type {
			display: inline-block;
			font-size: 12px;
			line-height: 14px;
			font-weight: normal;
			color: var($--theme-core-control-textbox-placeholder-text-color);
		}
	}

	.value {
		display: inline-block;
		width: 280px;
		height: 20px;
		font-size: 14px;
		line-height: 14px;
		padding: 2px 3px 4px 3px;
		margin: 2px 0;

		&,
		&:focus,
		&:focus-visible,
		&:active {
			color: var($--theme-core-control-text-color);
			background-color: transparent;
			border-width: 0 0 1px 0;
			border-style: solid;
			border-color: var($--theme-core-input-border-color);
			border-radius: 0;
			outline: 0;
			box-shadow: none;
		}

		// Active editor
		&:focus-visible,
		&:active {
			color: var($--theme-core-punch-color);
			border-color: var($--theme-core-punch-color);
		}
	}

	.description {
		font-size: 12px;
		line-height: 14px;
		color: var($--theme-core-control-textbox-placeholder-text-color);
		margin-top: 3px;
	}

	& > .dx-label {
		margin: 2px 0;
	}

	.spinner {
		position: relative;
		height: 25px;
		opacity: 1;
		transition: opacity linear 0.1s;

		&::before {
			animation: 2s linear infinite spinner;
			border: solid 2px var($--theme-core-control-text-color);
			border-bottom-color: var($--theme-core-nav-level-2-color);
			border-radius: 50%;
			content: '';
			opacity: inherit;
			height: 10px;
			left: 5%;
			position: absolute;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
			transform-origin: center;
			width: 10px;
			will-change: transform;
		}
	}

	.wizard-dropdown {
		color: var(--theme-core-control-text-color);
		background-color: var(--theme-core-control-background-color);
		border: 1px solid var(--theme-core-control-border-color);
		border-collapse: collapse;
		padding: 2px 3px;
	}
}
