.subscription-group {
	margin: 40px 0;

	.title {
		margin: 0;
		padding: 6px 12px;
		border-bottom: 1px solid var(--theme-core-layout-border-color);

		h4 {
			margin: 0;
		}

		.description {
			margin: 12px 0 6px 0;
		}
	}

	.subscription-item {
		display: flex;
		flex-flow: row wrap;
		align-items: baseline;
		gap: 12px;
		margin: 0;
		padding: 12px;
		border-bottom: 1px solid var(--theme-core-layout-border-color);

		&:hover {
			background-color: #00000033;
		}

		.subscription-mode-group {
			display: flex;
			flex-flow: row nowrap;
			gap: 0;
			margin-left: auto;
			width: 500px;

			& > .dx-button {
				flex-basis: 20%;
				margin: 0;
				padding: 6px;
				border-radius: 0;
				line-height: normal;

				&:first-child {
					border-radius: 4px 0 0 4px;
				}

				&:last-child {
					border-radius: 0 4px 4px 0;
				}

				&.remove-button {
					line-height: 0;
					flex-basis: 12%;

					&:hover {
						color: var(--theme-alertblock-critical-icon-color);
						border-color: var(--theme-alertblock-critical-icon-color);
					}
				}
			}
		}
	}
}
