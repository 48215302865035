@import '../../theme/variables.scss';

.author-info {
	&.micro {
		display: inline-block;
		font-size: 12px;
		line-height: 8px;

		.author-image {
			width: 28px;
			height: 28px;
			border-radius: 14px;
		}
	}

	&.mini {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 18px;

		.author-image {
			width: 28px;
			height: 28px;
			border-radius: 14px;
		}
	}

	&.full {
		border: 1px solid var($--theme-core-layout-border-color);
		border-radius: 4px;
		padding: 12px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 30px;

		.author-image {
			height: 140px;
			width: 140px;
			border-radius: 50%;
			float: left;
			flex-grow: 0;
			flex-shrink: 0;
		}

		.author-content {
			flex-grow: 1;

			.author-name {
				font-size: 26px;
				font-weight: bold;
			}

			.author-subtitle {
				font-size: 18px;
			}

			.author-bio-text {
				text-align: justify;
				margin-bottom: 0;
			}
		}
	}
}

.author-info-placeholder {
	&.micro {
		.inline-placeholder {
			border-radius: 14px;
		}
	}
}
