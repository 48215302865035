.sdk-doc-explorer {
	display: flex;
	flex-flow: row nowrap;
	gap: 20px;
	min-height: 70vh;

	.top-ref {
		width: 0;
		height: 0;
		align-self: flex-start;
	}

	.sdk-doc-explorer-sidebar {
		width: 300px;
		flex-grow: 0;
		flex-shrink: 0;
	}

	.doc-content {
		margin-left: 20px;
		overflow: hidden;
	}
}
